'use client'

import { useState, useEffect } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { useRouter } from 'next/navigation'
import { FaGlobe } from "react-icons/fa";

export default function LocaleSwitcher({ language }) {
  const router = useRouter()

  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()

  const redirectedPathName = (locale) => {
    const languages = ['en', 'cn', 'th']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)
  const setCookieByRoute = () => {
    const languages = ['en', 'cn', 'th']
    let langCookie = getCookie('NEXT_LOCALE')
    if (!pathName) return '/'
    const segments = pathName.split('/')
    if (languages.includes(segments[1]) && (langCookie !== undefined && langCookie !== segments[1])) {
      setCookie('NEXT_LOCALE', segments[1]);
      setSelected(segments[1])
      router.refresh()
    }
  }

  const langList = i18n.locales

  useEffect(() => {
    const triggerLangChange = () => {
      setCookieByRoute()
    }
    triggerLangChange()
  }, [])
  
  
  // setCookieByRoute()

  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
    setSelected(localeValue)
    router.refresh()
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
    // console.log('clicked')
    // console.log(isOpen)
  }
  
  return (
    <>
      {langList.length > 2 ? (
        <div className="relative z-[100]">
          <Listbox value={selected} onChange={setSelected} className='z-[100] mr-3'>
            <div className="z-[100]">
              <Listbox.Button className="cursor-pointer text-light text-center border-[1px] border-light border-solid p-1 flex items-center rounded-sm">
                {/* <BiWorld color='white' /> */}
                <FaGlobe color='white'/><span className="mx-1 truncate text-light text-md">{selected !== 'cn' ? selected.toUpperCase() : '中文'}</span>
                {/* <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span> */}
              </Listbox.Button>
                <Listbox.Options className="absolute z-[100] mt-1 max-h-60 w-[80px] rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none text-sm">
                {i18n.locales.map((locale) => {
                  if (locale !== 'default') {
                    return (
                    <Listbox.Option
                      key={locale}
                      className={({ active }) =>
                        `z-[100] cursor-default select-none ${
                          active ? 'text-light bg-maincolor' : 'text-maincolor'
                        }`
                      }
                      value={locale}
                    >
                      {({ selected }) => (
                        <>
                              <Link key={locale} onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>
                                  <div className='text-center text-sm w-[80px]  px-4 py-2 rounded-md  hover:text-light '>
                                    {locale !== 'cn' ? locale.toUpperCase() : '中文'}
                                  </div>
                              </Link>
                          {/* <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {locale}
                          </span> */}

                        </>
                      )}
                    </Listbox.Option>
                    ) 
                  }
                })}
                </Listbox.Options>
            </div>
          </Listbox>
        </div>
      ) : null}
    </>
  )
}